import React, { useState, useEffect } from 'react'
import star from '../images/home/star.png'
function Stories ({ stories }) {
    const [selected, setSelected] = useState([])
    function showStars () {
        const stars = []
        for (let i = 0; i < selected.stars; i++) {
            const id = selected.title + i
            stars.push(<img alt="star-img" key={id} src={star} />)
        }
        return stars
    }
    useEffect(() => {
        if (stories) {
            setSelected(stories[0])
        }
    }, [stories])
    return (
        <div className="row stories">
            <div className="col-md-6">
                {stories && stories.map(story => (
                    <div
                        role="button"
                        tabIndex={selected.id}
                        onKeyDown={() => setSelected(story)}
                        onClick={() => setSelected(story)}
                        className={`stories__card ${selected.id === story.id && 'stories__card--selected'}`}
                        key={story.id}
                    >
                        <img src={story.img} alt="optiwe" className="ow-banner__img d-none" />
                        <div className="stories__card-content">
                            <p className="stories__title">{story.name}</p>
                            <p className="stories__text">{story.jobTitle}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="col-md-6">
                {selected && (
                    <div className="selected-story">
                        <h3 className="selected-story__title">{selected.title}</h3>
                        {showStars()}
                        <p className="mt-3">{selected.description}</p>
                    </div>
                )}
            </div>
        </div>
    )
}
export default Stories
