import React from 'react'
// clients
// Integrations
import hubspot from '../../images/home/integrations/hubspot.png'
import zoho from '../../images/home/integrations/zoho.png'
import salesforce from '../../images/home/integrations/salesforce.png'
import drive from '../../images/home/integrations/drive.png'

const Integrations = () => (
    <div className="row">
        <div className="col-6 col-md-3 mb-3">
            <div className="ow-card ow-card--client ow-card--no-background">
                <img src={hubspot} alt="Optiwe client" />
            </div>
        </div>
        <div className="col-6 col-md-3 mb-3">
            <div className="ow-card ow-card--client ow-card--no-background">
                <img src={zoho} alt="Optiwe client" />
            </div>
        </div>
        <div className="col-6 col-md-3 mb-3">
            <div className="ow-card ow-card--client ow-card--no-background">
                <img src={salesforce} alt="Optiwe client" />
            </div>
        </div>
        <div className="col-6 col-md-3 mb-3">
            <div className="ow-card ow-card--client ow-card--no-background">
                <img src={drive} alt="Optiwe client" />
            </div>
        </div>
    </div>
)

export default Integrations
